<template>
  <div class="row">
    <b-modal
      v-if="productToUpdate"
      @ok="handleUpdateProduct()"
      id="update-product-modal"
      :title="$gettext('Update product')"
      :cancel-title="$gettext('Cancel')"
    >
      <div>
        <h4>{{ productToUpdate.title }}</h4>
        <jobboard-icon size="4" class="mr-2" :name="productToUpdate.icon" />

        <p>Quantity: {{ productToUpdate.display_quantity }}</p>
        <p>Amount: {{ productToUpdate.display_price }}$</p>

        <div class="btn-group btn-group-sm">
          <button
            @click="productToUpdate.down()"
            class="btn btn-outline-default btn-round"
          >
            <i class="nc-icon nc-simple-delete"></i>
          </button>

          <button
            @click.prevent="productToUpdate.up()"
            class="btn btn-outline-default btn-round"
          >
            <i class="nc-icon nc-simple-add"></i>
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="unsubscribe-reason-modal"
      @ok="handleUnsubscribeReason()"
      :title="$gettext('Cancel subscription')"
      :cancel-title="$gettext('Cancel')"
      hide-header-close
    >
      <div>
        <b-form-group
          :label="$gettext('Why do you want to cancel your subscription?')"
        >
          <b-form-radio-group
            v-model="unsubscribeReasonSelected"
            :options="unsubscribeReasonOptions"
            name="unsubscribe-reason"
            stacked
          ></b-form-radio-group>
        </b-form-group>
      </div>
    </b-modal>
    <EmptyState
      v-if="showEmptyState"
      :to="{ name: 'boutique' }"
      icon="jbsmd-crown"
      :message="$gettext('You don\'t have any products')"
      :customButtonText="$gettext('Add a product')"
    />
    <div v-show="!showEmptyState" class="col-md-12 ">
      <ContentHeader :title="$gettext('Manage subscription')">
        <template #description>
          {{ periodEndMessage }}
        </template>
        <template #options>
          <router-link class="btn btn-primary align-self-baseline" to="boutique"
            ><i class="fas fa-plus mr-2"></i
            ><translate>Add products</translate></router-link
          >
        </template>
      </ContentHeader>
      <div class="card">
        <div class="card-body">
          <b-table :items="products" :fields="fields">
            <template #cell(product)="{item}">
              <div>
                <jobboard-icon class="mr-2" :name="item.icon" />
                {{ item.title }}
              </div>
            </template>
            <template #cell(limit)="{item}">
              {{ item.is_unlimited ? $gettext("Unlimited") : item.limit }}
            </template>
            <template #cell(actions)="{item}">
              <b-button
                v-if="!item.is_unlimited"
                @click="showUpdateProductModal(item)"
                variant="warning"
                class="btn btn-icon btn-sm ml-1"
                v-b-tooltip.hover
                :title="$gettext('Edit')"
                ><i class="fa fa-edit"></i
              ></b-button>
              <b-button
                v-if="!item.cancel_at_period_end"
                @click="handleRemove(item.id)"
                variant="danger"
                class="btn btn-icon btn-sm ml-1"
                v-b-tooltip.hover
                :title="$gettext('Remove')"
                ><i class="fa fa-times"></i
              ></b-button>
            </template>
          </b-table>
          <div>
            <router-link class="btn btn-primary mr-4" to="boutique"
              ><i class="fas fa-plus mr-2"></i
              ><translate>Add products</translate></router-link
            >
            <a
              v-if="!isCancelAtPeriodEnd"
              href="#"
              @click.prevent="handleCancelSubscription"
              class="text-muted"
              >{{ cancelSubMsg }}</a
            >
            <a
              v-if="isActive && isCancelAtPeriodEnd"
              href="#"
              @click.prevent="handleReactivateSubscription"
              ><translate>Reactivate subscription</translate></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import JobboardIcon from "@/components/JobboardIcon";
import { createProduct } from "@/views/Subscription/product";
import { msgBoxConfirm } from "@/utils/modal";
import EmptyState from "@/components/EmptyState";
import ContentHeader from "@/components/ContentHeader";
import { VBTooltip } from "bootstrap-vue";
Vue.directive("b-tooltip", VBTooltip);
export default {
  components: {
    EmptyState,
    JobboardIcon,
    ContentHeader
  },
  data: () => ({
    productToUpdate: null,
    cancelSubMsg: "",
    reactivateSubMsg: "",
    unsubscribeReasonSelected: null
  }),
  created() {
    // Put this here because of weird behaviour of the <translate> component.
    this.cancelSubMsg = this.$gettext("Cancel subscription");
    this.reactivateSubMsg = this.$gettext("Reactivate subscription");
    this.fetch().finally(this.stopFetching);
  },
  computed: {
    periodEndMessage() {
      if (this.hasPeriodEnd) {
        if (this.isCancelAtPeriodEnd) {
          return `${this.$gettext("Your subscription will expired on")} ${
            this.currentPeriodEnd
          }`;
        }
        return `${this.$gettext("Your next subscription billing date is")} ${
          this.currentPeriodEnd
        }`;
      }
      return "";
    },
    showEmptyState() {
      return this.products.length < 1 && !this.isFetching;
    },
    ...mapGetters("app", ["isFetching"]),
    ...mapGetters("subscriptions", [
      "hasPeriodEnd",
      "products",
      "isCancelAtPeriodEnd",
      "currentPeriodEnd",
      "isActive"
    ]),
    fields() {
      return [
        { key: "product", label: this.$gettext("Product") },
        { key: "limit", label: this.$gettext("Limit") },
        {
          key: "actions",
          tdClass: "td-action-2",
          label: this.$gettext("Actions")
        }
      ];
    },
    unsubscribeReasonOptions() {
      return [
        this.$gettext("Unsatisfied with the product"),
        this.$gettext("I no longer need to recruit"),
        this.$gettext("I will use a new way to recruit")
      ];
    }
  },
  methods: {
    ...mapActions("app", ["stopFetching", "startFetching"]),
    ...mapActions("subscriptions", [
      "fetch",
      "updateProduct",
      "deleteProduct",
      "cancel",
      "reactivate",
      "reactivateProduct"
    ]),
    handleRemove(id) {
      msgBoxConfirm().then(value => {
        if (value.isConfirmed === true) {
          this.startFetching();
          this.deleteProduct(id)
            .then(this.fetch)
            .catch(({ response }) => {
              if (response.status === 422) {
                for (const row in response.data) {
                  this.$toast.warning(response.data[row][0]);
                }
              }
            })
            .finally(() => this.stopFetching());
        }
      });
    },
    handleCancelSubscription() {
      msgBoxConfirm(
        this.$gettext("Are you sure you want to cancel your subscription?")
      ).then(value => {
        if (value.isConfirmed === true) {
          this.$nextTick(() => this.$bvModal.show("unsubscribe-reason-modal"));
        }
      });
    },
    handleUnsubscribeReason() {
      const payload = {
        reason: this.unsubscribeReasonSelected
      };
      this.startFetching();
      this.cancel(payload)
        .then(this.fetch)
        .finally(() => this.stopFetching());
    },
    handleReactivateSubscription() {
      this.startFetching();
      this.reactivate()
        .then(this.fetch)
        .finally(() => this.stopFetching());
    },

    handleUpdateProduct() {
      this.startFetching();
      this.updateProduct(this.productToUpdate)
        .then(async ({ data: paymentIntent }) => {
          if (paymentIntent.status === "requires_action") {
            const res = await this.$stripe.confirmCardPayment(
              paymentIntent.client_secret
            );
            if (res.error) {
              this.$toast.error(res.error.message);
            } else {
              this.$toast.info(
                "Confirmation succeeded! Your subscription might take a few minutes to update."
              );
            }
          }
        })
        .finally(() => this.fetch().finally(this.stopFetching));
    },
    async showUpdateProductModal({
      id,
      step,
      limit,
      price,
      quantity_included,
      icon,
      title,
      unit_amount
    }) {
      this.productToUpdate = createProduct({
        id,
        step,
        limit,
        price,
        quantity_included,
        icon,
        title,
        unit_amount
      });
      this.$nextTick(() => this.$bvModal.show("update-product-modal"));
    }
  }
};
</script>
