<template>
  <div class="content-header">
    <div class="row align-items-center">
      <div class="col-sm mb-2 mb-sm-0">
        <h1 class="page-header-title">{{ title }}</h1>
        <p class="content-header-description category">
          <slot name="description"></slot>
        </p>
      </div>
      <div class="col-sm-auto">
        <slot name="options"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
};
</script>
<style scoped>
.content-header {
  margin-bottom: 1rem;
}
.content-header h1 {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 10px;
  margin-top: 0;
}
.content-header p {
  margin-bottom: 0;
}
</style>
