export const createProduct = product => {
  product.quantity = (product.limit - product.quantity_included) / product.step;
  return Object.assign(
    {
      get display_quantity() {
        return this.quantity * this.step + this.quantity_included;
      },
      get display_price() {
        return this.quantity * this.unit_amount;
      }
    },
    {
      ...product,
      up() {
        this.quantity = Math.max(1, this.quantity + 1);
      },
      down() {
        this.quantity = Math.max(1, this.quantity - 1);
      }
    }
  );
};
