<template>
  <div class="d-flex justify-content-center w-100 p-lg-5">
    <div class="text-center m-lg-5">
      <div v-if="icon">
        <i :class="icon" class="display-4"> </i>
      </div>
      <div class="mx-auto my-4">{{ message }}</div>
      <router-link v-if="showButton" class="btn btn-primary" :to="to">
        {{ buttonText }}
      </router-link>

      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true
    },
    customButtonText: {
      type: [String, Boolean],
      required: false,
      default: false
    },
    to: {
      type: [String, Object, Boolean],
      default: false
    },
    icon: {
      type: String
    },
    showButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    buttonText() {
      return this.customButtonText || this.$gettext("Create one");
    }
  }
};
</script>
