<template>
  <i :class="getClass"></i>
</template>

<script>
// @todo give this component a more generic name.
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false
    }
  },
  computed: {
    getClass() {
      return [`jbsmd-${this.name}`, this.size ? `display-${this.size}` : ""];
    }
  }
};
</script>
